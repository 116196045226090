
import React, {useContext, useState, useEffect} from 'react';
import {ThemeContext} from '../../../../providers/theme';
import blueprint from '../../config/default-blueprint.json';
import { useViewport } from 'react-viewport-provider';
import {RegistrationPageV} from 'react-registration-template-1';
import {
  AccountProfile,
  accountProfileDS, accountProfileDV,
  KEY as ACCOUNT_PROFILE_KEY,
  SENDER_ID as ACCOUNT_PROFILE_SENDER_ID,
} from './components/AccountProfile';
import {OrganisationProfile} from './components/OrganisationProfile';
import {WalletRegistration} from './components/WalletRegistration';
import {DataSet, createNewDataPipeFrom} from 'vis-data';
import {IProps} from './index';
import Alert from 'rsuite/lib/Alert';
import {humanize} from 'uno-js';
import _ from 'lodash';
import { defaultValuesDS } from 'react-form-rs';
import {LogoSpinnerV} from 'react-logo-spinner-ext';
import {TermsAndConditions} from './components/TermsAndConditions';

export const SENDER_ID = 'Registration:RegistationPageLG';
export const registrationPageLGDS: any = new DataSet({
  fieldId: 'key',
});
export const KEY = 'RegistrationPageLG';
registrationPageLGDS.add([{key: KEY}], SENDER_ID);

export const RegistrationPageLG = (props: IProps) => {
  const [validation, setValidation] = useState<any>();
  const [enableNext, setEnableNext] = useState<boolean>(true);
  const [enablePrev, setEnablePrev] = useState<boolean>(true);
  const [step, setStep] = useState<number>(0);
  const [accepted, setAccepted] = useState<boolean>();
  const theme = useContext(ThemeContext);
  const {width, height} = useViewport();
  const [termsChecked, setTermsChecked] = useState<boolean>(false);
  const [orgTermsChecked, setOrgTermsChecked] = useState<boolean>(false);


  const isOrganization = (Array.isArray(props?.userType) && props?.userType.includes("ISSUER_ADMIN"))||
  (Array.isArray(props?.userType) && props?.userType.includes("VERIFIER_ADMIN")) ||
  (Array.isArray(props?.userType) && props?.userType.includes("NETWORK_OPERATOR_ADMIN"));

  useEffect(()=>{
    if(props.isAccountCreated){
      setStep(step +1);
    }
  },[props.isAccountCreated]);
  
  useEffect(()=>{
    if(props.isOrgCreated){
      setStep(step+1);
    }
  }, [props.isOrgCreated]);

  const checkforEquality = () => {
    const profile: any = accountProfileDS.get(ACCOUNT_PROFILE_KEY);
    if (profile && profile.defaultValues) {
      const passwordItem = _.find(profile.defaultValues, ['key', 'password']);
      const confirmPasswordItem = _.find(profile.defaultValues, ['key', 'confirmPassword']);
      if(passwordItem?.defaultValue === confirmPasswordItem?.defaultValue) {
        return true;
      }
    }
    return false;
  }

  const onRegistrationComplete = () => {
    setValidation({
      ...validation
    });
    if(!isOrganization){
      let password = defaultValuesDS.get('password')?.defaultValue;
      let cnfPassword = defaultValuesDS.get('confirmPassword')?.defaultValue;
      if(password === cnfPassword){
      if(termsChecked){
        let firstName = defaultValuesDS.get('firstName')?.defaultValue;
        let lastName = defaultValuesDS.get('lastName')?.defaultValue;
        props.onAccountCreation &&
        props.onAccountCreation(firstName, lastName, password);
      }
      else{
        Alert.error(`Read and accept Terms & Condition`, 10000)
      }
    }
    else {
      Alert.error(`password does not match. Please try again`, 10000)
    }
    }
    else {
      let formData = {
        // name: defaultValuesDS.get('organizationName')?.defaultValue,
        website: defaultValuesDS.get('organizationWebsite')?.defaultValue,
        phoneNo: defaultValuesDS.get('organizationPhone')?.defaultValue,
        address: {
          addressLine1: defaultValuesDS.get('organizationAddressLine1')
            ?.defaultValue,
          addressLine2: defaultValuesDS.get('organizationAddressLine2')
            ?.defaultValue,
          city: defaultValuesDS.get('organizationCity')?.defaultValue,
          state: defaultValuesDS.get('organizationState')?.defaultValue,
          country: defaultValuesDS.get('organizationCountry')?.defaultValue,
        },
      };
      props.onOrganizationCreate && props.onOrganizationCreate(formData);
    }
  }

  const ontermsCheckboxChange = (checked:boolean) => {
    setTermsChecked(checked);
  }
  const onOrgTermsChange = (checked: boolean)=>{
    setOrgTermsChecked(checked);
  }

  const handleValidateResult = (key: string, formContext?: any, status?: 'success' | 'error', validationResult: any) => {
    const item = registrationPageLGDS.get(KEY);
    let toProceed = true;
    if (item && item.currentRenderKey && item.currentRenderKey === key && status === 'success') {
      if (!enableNext) setEnableNext(true);
      if (!enablePrev) setEnablePrev(true);
     if(key === 'accountProfile') {
        toProceed = checkforEquality();
        if (toProceed) {
          if(isOrganization){
            if(termsChecked && orgTermsChecked){
              let firstName = defaultValuesDS.get('firstName')?.defaultValue;
              let lastName = defaultValuesDS.get('lastName')?.defaultValue;
              let password = defaultValuesDS.get('password')?.defaultValue;
              props.onAccountCreation &&
              props.onAccountCreation(firstName, lastName, password);
            }
            else{
              Alert.error(`Read and accept Terms & Condition`, 10000)
            }
          }
          else {
              if(termsChecked){
                let firstName = defaultValuesDS.get('firstName')?.defaultValue;
                let lastName = defaultValuesDS.get('lastName')?.defaultValue;
                let password = defaultValuesDS.get('password')?.defaultValue;
                props.onAccountCreation &&
                props.onAccountCreation(firstName, lastName, password);
              }
              else{
                Alert.error(`Read and accept Terms & Condition`, 10000)
              }
          }
          
          if (item && item.action && item.action === 'next') {
            // setStep(step + 1);
          }
          if (item && item.action && item.action === 'prev') {
            setStep(step - 1);
          }
        }
        else {
          Alert.error(`password does not match. Please try again`, 10000)
        }
     }
      
      if(key === 'organisationProfile'){
        let formData = {
          // name: defaultValuesDS.get('organizationName')?.defaultValue,
          website: defaultValuesDS.get('organizationWebsite')?.defaultValue,
          phoneNo: defaultValuesDS.get('organizationPhone')?.defaultValue,
          address: {
            addressLine1: defaultValuesDS.get('organizationAddressLine1')
              ?.defaultValue,
            addressLine2: defaultValuesDS.get('organizationAddressLine2')
              ?.defaultValue,
            city: defaultValuesDS.get('organizationCity')?.defaultValue,
            state: defaultValuesDS.get('organizationState')?.defaultValue,
            country: defaultValuesDS.get('organizationCountry')?.defaultValue,
          },
        };
        props.onOrganizationCreate && props.onOrganizationCreate(formData);
      }     
        
    }
    if (
      item &&
      item.currentRenderKey &&
      item.currentRenderKey === key &&
      status === 'error'
    ) {
      Alert.error(`Error in filling form of ${humanize(key)}`, 10000);
    }
    setValidation({
      ...validation,
      [key]: false,
    });
  }
  
  const renderContent = (key: string) => {
    registrationPageLGDS.update({
      key: KEY,
      currentRenderKey: key,
    });
    if (key === 'accountProfile') {
      if(props.createAccountLoading){
        return <LogoSpinnerV imageStyle={{left:'50%'}} />
      }
      else {
        return <AccountProfile 
              validate={validation && validation[key]} 
              onValidateResult={handleValidateResult.bind(null, key)}
              verifiedEmail={props?.verifiedEmail}
              userType={props?.userType}
              termsAccepted={ontermsCheckboxChange}
              orgTermsAccepted={onOrgTermsChange}
            />;
        // return <TermsAndConditions 
        // termsandcondition={props?.termsandcondition}
        // />
      }
      
    }
    if (key === 'organisationProfile')  {
      if(props.creteOrgLoading){
        return <LogoSpinnerV imageStyle={{left:'50%'}} />
      }
      else {
        return (
          <OrganisationProfile
            validate={validation && validation[key]}
            onValidateResult={handleValidateResult.bind(null, key)}
          />
        );
      }      
    }
    if (key === 'termsAndCondition') {
      return <TermsAndConditions 
      termsandcondition={props?.termsandcondition}
      termsAccepted={(checked) =>setAccepted(checked)}
      />
    }
  }
  const handleNext = (currentStep: number, currentKey: string) => {
     setValidation({
       ...validation,
       [currentKey]: true,
     });
     registrationPageLGDS.update({
       key: KEY,
       action: 'next',
     });
     if (currentKey === 'walletRegistration') {
       setStep(step + 1);
     }
  };

  const handlePrev = (currentStep: number, currentKey: string) => {
    setValidation({
      ...validation,
      [currentKey]: true,
    });
    registrationPageLGDS.update({
      key: KEY,
      action: 'prev',
    });
    if(currentKey === 'walletRegistration') {
      setStep(step - 1)
    }
  };

  return (
    <RegistrationPageV
      containerStyle={{width: 1 * width, height: 1 * height}}
      title={props.title}
      leftAvatar={props.leftAvatar}
      items={props.items ?? []}
      onHeaderLogoPress={props.onHeaderLogoPress}
      waveColor={theme?.palette.secondary}
      renderStepperContent={renderContent}
      stepperWidth={0.9 * width}
      stepperHeight={0.8 * height}
      stepperColSpan={6}
      onNext={handleNext}
      onPrev={handlePrev}
      step={step}
      headerTitleToDisplay={props.headerTitleToDisplay}
      enableNextonCurrentStep={enableNext}
      enablePrevonCurrentStep={enablePrev}
      onStepperDonePress={onRegistrationComplete}
    />
  );
};
