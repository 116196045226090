// @ts-nocheck
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import React from 'react';
import { DashboardScreen } from '../screens/Dashboard';
import { LandingScreen } from '../screens/LandingPage';
import { LoginScreen } from '../screens/Login';
import { RegistrationScreen } from '../screens/Registration';
import { ForgotPasswordScreen } from '../screens/ForgotPassword';
import { ResetPasswordScreen } from '../screens/ResetPassword';
import { BrowserRouter as Router, Switch, Route, Link, Redirect } from 'react-router-dom';

import { useAuth } from '../services/auth-service/auth.provider';

const { isAuthenticated } = useAuth();

const routes = [
  {
    path: '/login',
    component: LoginScreen,
  },
  {
    path: '/register',
    component: RegistrationScreen,
  },
  {
    path: '/dashboard',
    component: DashboardScreen,
    protected: true,
  },
  {
    path: '/forgotpassword',
    component: ForgotPasswordScreen,
  },
  {
    path: '/resetpassword',
    component: ResetPasswordScreen,
  },
  {
    path: '/',
    component: LandingScreen,
  },
];

// const fakeAuth = {
//   isAuthenticated: false,
//   authenticate(cb) {
//     fakeAuth.isAuthenticated = true;
//     setTimeout(cb, 100); // fake async
//   },
//   signout(cb) {
//     fakeAuth.isAuthenticated = false;
//     setTimeout(cb, 100);
//   },
// };

const RouterConfig = props => (
  <Switch>
    {/* <Route path="/login" component={LoginScreen} /> */}
    <Route path="/register" component={RegistrationScreen} />
    {/* <Route path="/dashboard" component={DashboardScreen} /> */}
    <PrivateRoute path="/dashboard" component={DashboardScreen} />
    {/* <PrivateRoute path="/verify" component={VerifyUserScreen} /> */}
    <Route path="/forgotpassword" component={ForgotPasswordScreen} />
    <Route path="/resetpassword" component={ResetPasswordScreen} />
    <Route path="/" component={LoginScreen} />
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props =>
      isAuthenticated() ? (
        <Component {...props} />
      ) : (
          <Redirect
            to={{
              pathname: '/',
            }}
          />
        )
    }
  />
);

function RouteWithSubRoutes(route) {
  return (
    <Route
      path={route.path}
      render={props => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
}

const Stack = createStackNavigator();

const AppNavigatorInternal = (): JSX.Element => {
  return (
    <NavigationContainer>
      <Stack.Navigator headerMode={'none'}>
        <Stack.Screen name="Dashboard" component={DashboardScreen} />
      </Stack.Navigator>
    </NavigationContainer>
  );
}

export const AppNavigator = (): JSX.Element => {
  return (
    <Router>
      <RouterConfig />
    </Router>
  );
};
