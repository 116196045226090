import React, {useContext} from 'react';
import {StyleSheet, View} from 'react-native';
import {ThemeContext} from '../../../../providers/theme';
import config from '../../config/default-blueprint.json';
import {useViewport} from 'react-viewport-provider';
import {LandingPageV} from 'react-landing-page-template-1';
import { useHistory, useLocation } from 'react-router-dom';
import {FooterDetail} from './components/Footer';
import {EventBus} from 'event-bus-vfs';
import {FORM_VALUES} from 'react-form-rs';

const BP = config.LandingPage;
let formContext = {};

interface props {}

export const LandingTemplateSM = ({}: props) => {
  const history = useHistory();
  const theme = useContext(ThemeContext);
  const {width, height} = useViewport();
  const [validateForm, setValidateForm] = React.useState<any>(null);
  React.useEffect(() => {
    const app = EventBus.on(FORM_VALUES, value => {
      // console.log('formContext event', formContext);
      formContext = {...value};
    });
    return function () {
      app.off();
    };
  }, []);

  const styles = StyleSheet.create({
    containerStyle: {
      width: 1 * width,
       height: 1 * height,
      backgroundColor: '#FAFBFC',
    },
  });

  const onFooterNavSelect= (eventKey: string) =>{
    if(eventKey === 'terms'){
        history.push('/termsandcondition');
        window.scrollTo(0,0);
    }
    if(eventKey === 'privacy'){
        history.push('/privacy');
        window.scrollTo(0,0)
    }
    if(eventKey === 'home'){
        history.push('/')
    }
}


  return (
    <View style={StyleSheet.flatten([styles.containerStyle])}>
      <LandingPageV
        //containerStyle={StyleSheet.flatten([styles.containerStyle])}
        title={BP.title}
        leftAvatar={BP.leftAvatarURL}
        // loginButtonTitle={BP.loginButtonTitle}
        loginButtonColor={BP.loginButtonColor}
        // signupButtonTitle={BP.signupButtonTitle}
        signupButtonColor={theme?.palette.secondary}
        bodyTitle={BP.bodyTitle}
        bodySubtitle={BP.bodySubtitle}
        formFields={BP.formFields}
        formContext={formContext}
        validateForm={validateForm}
        onValidateResult={(formContext, status, validateResult) => {
          // console.log('formcontext', formContext);
          // console.log('status', status);
          // console.log('validateResult', validateResult);
        }}
        onGetStarted={() => {
          // console.log('get Started');
          setValidateForm({
            ...formContext,
          });
        }}
        waveColor={theme?.palette.secondary}
      />
      <FooterDetail 
      onFooterNavSelect={onFooterNavSelect}
      />
    </View>
  );
};
