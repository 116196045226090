import React, {useState} from 'react';
import Typography from '@material-ui/core/Typography';
import List from 'rsuite/lib/List';
import {StyleSheet} from 'react-native';
import { FBGridV, FBItemV } from 'react-flexbox-grid-rs';
import {useViewport} from 'react-viewport-provider';
import Checkbox from 'rsuite/lib/Checkbox';
import BP from './config/default-blueprint.json';

interface Iprops{
    termsandcondition?: any;
    termsAccepted?(checked?:boolean):void;
    
}



export const TermsAndConditions = (props: Iprops) =>{
  const [checked, setChecked] = useState<boolean>(false);
    let termsData:any = [];
    const {height} = useViewport();

    const styles= {
        containerStyle: {
            padding: '2%',
            height: 0.55 * height,
            overflowY:'scroll'
            //borderRight: '2px solid #ccc'
          },
          detailStyle: {
            borderRight: '2px solid #ccc',
            padding: 20,
            textAlign: 'center',
            '@media (max-width: 568px)': {
              border: 'none',
            },
          },
          paraStyle: {
            textAlign: 'left',
            lineHeight: 1.5,
            paddingBottom: 10,
            paddingLeft: 20,
          },
          listStyle: {
            textAlign: 'left',
            lineHeight: 1.5,
            paddingBottom: 5,
            cursor: 'pointer',
            pointerEvents: 'auto',
          },
          leftImgStyle: {
            maxWidth: 70,
            height: 'auto',
            paddingRight: 20,
          },
          leftHeadingStyle: {
            fontSize: 24,
            textAlign: 'left',
            paddingLeft: 20,
          },
    }

    const onHandleChange = (value:any, checked:boolean) => {
      setChecked(checked);
      props.termsAccepted && props.termsAccepted(checked);
    }

    const getSubList = (subList:any) => {
        return (
          <ul>
            {subList && subList.length > 0
              ? subList.map((key, index) => (
                  <li key={index.toString()}>
                    <Typography style={StyleSheet.flatten([styles.paraStyle])}>{key.p}</Typography>
                  </li>
                ))
              : null}
          </ul>
        );
      };
    
    if (props?.termsandcondition) {
        props?.termsandcondition?.data &&
        props?.termsandcondition?.data.forEach((element, index) => {
            if (element.p) {
              termsData.push(
                <Typography style={StyleSheet.flatten([styles.paraStyle])} key={index.toString()}>
                  {element.p}
                </Typography>
              );
            }
            else if(element.heading){
                termsData.push(
                    <Typography style={StyleSheet.flatten([styles.leftHeadingStyle])} key={index.toString()}>
                      {element.heading}
                    </Typography>
                  );
            }
            else if (element.li && element.li.length > 0) {
              element.li.forEach((ele, i) => {
                termsData.push(
                  <List>
                    <List.Item>
                      <Typography style={StyleSheet.flatten([styles.listStyle])}>{ele.title}</Typography>
                    </List.Item>
                    {getSubList(ele.sublist)}
                  </List>
                );
              });
            } 
          });
    }
    return (
      <>
        <FBGridV style={StyleSheet.flatten([styles.containerStyle])}>
          <FBItemV colspan={24}>
          {termsData}
          </FBItemV>        
        </FBGridV>
        <Checkbox
          style={{padding:'2%'}}
          checked={checked}
          onChange={(value, checked)=>onHandleChange(value, checked)}
        >
          {BP?.acceptTerms}
        </Checkbox>
      </>
    )
}

