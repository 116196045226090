import React, {useEffect, useState} from 'react';
import {useViewport} from 'react-viewport-provider';
import {RegistrationPageLG} from './Registration.lg';
import {RegistrationPageSM} from './Registration.sm';
import {registrationItemsDS, registrationItemsDV, SENDER_ID} from '../../container';

type IVolatitle = {
  items?: any[];
}

export type IProps = {
  onHeaderLogoPress?(): void;
  title?: string;
  leftAvatar?: string;
  headerTitleToDisplay?: boolean;
  onAccountCreation?(firstName?: string, lastName?:string, password?:string): void;
  onOrganizationCreate?(formData: any): void;
  verifiedEmail?:string;
  createAccountLoading?:boolean;
  isAccountCreated?:boolean;
  creteOrgLoading?:boolean;
  isOrgCreated?:boolean;
  termsandcondition?:any;
  onCompleteRegistration?(accepted?:boolean):void;
  userType?:any;
  orgTermsAccepted?(checked?:boolean):void;
  termsAccepted?(checked?:boolean):void;
} & IVolatitle;


export const RegistrationPage = (props: IProps) => {
  const [items, setItems] = useState<any[]>(
    registrationItemsDV ? registrationItemsDV.get() : [],
  );
  useEffect(() => {
    // function onItemChange(event: any, properties: any, senderId: any) {
    //   if (senderId === SENDER_ID) {
    //     setItems([...registrationItemsDV.get()]);
    //   }
    // }
    // if (registrationItemsDV) {
    //   registrationItemsDV.on('*', onItemChange);
    // }
    // return function cleanup() {
    //   if (registrationItemsDV) {
    //     registrationItemsDV.off('*', onItemChange);
    //   }
    // };
  }, []);

  const {bp} = useViewport();
  const _props : IProps = props ? { ...props} : {};
  // _props.items = items;
  
  if (bp === 'xs' || bp === 'sm') {
    return <RegistrationPageSM {..._props} />;
  }

  // if (bp === 'md') {
  //   return <div />;
  // }

  //bp === 'lg' || bp === 'xl' || bp === 'md
  return <RegistrationPageLG {..._props} />;
};
