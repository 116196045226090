/**
  * <FormValidator />
  */

import React from 'react';
import 'rsuite/dist/styles/rsuite-default.css';
import Button from 'rsuite/lib/Button';
import List from 'rsuite/lib/List';
import Modal from 'rsuite/lib/Modal';
import Icon from 'rsuite/lib/Icon';
import xss from 'xss';
const myxss = new xss.FilterXSS({
  whiteList: {
    u: [],
    br: [],
    b: [],
    i: [],
    ol: ['style'],
    ul: ['style'],
    li: [],
    p: ['style'],
    sub: [],
    sup: [],
    div: ['style'],
    em: [],
    strong: [],
    span: ['style'],
  },
});

export default class FormValidator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: [],
      show: true,
    };
  }

  componentDidMount() {
    this.subscription = this.props.emitter.addListener('formValidation', (errors) => {
      if (errors &&  Array.isArray(errors) && errors.length > 0) {
         this.setState({ errors, show: true });
      }

    });
  }

  componentWillUnmount() {
    this.subscription.remove();
  }

  dismissModal(e) {
    e.preventDefault();
    this.setState({ errors: [], show: false });
  }


  render() {
    // return (
    //   <div>
    //     { this.state.errors.length > 0 &&
    //       <div className="alert alert-danger validation-error">
    //         <div className="clearfix">
    //           <i className="fas fa-exclamation-triangle float-left"></i>
    //           <ul className="float-left">
    //             {errors}
    //           </ul>
    //         </div>
    //         <div className="clearfix">
    //           <a className="float-right btn btn-default btn-sm btn-danger" onClick={this.dismissModal.bind(this)}>Dismiss</a>
    //         </div>
    //       </div>
    //     }
    //   </div>
    // );
    if (this.state.errors.length > 0) {
        return (
      <div className="modal-container">
          <Modal overflow={true} show={this.state.show} onHide={this.dismissModal.bind(this)}>
          <Modal.Header>
                <Modal.Title>
                  <div style={{ flexDirection: 'row' }}>
                    <Icon icon={'exclamation-triangle'} style={{ color: 'red', paddingRight: 20 }} size={'3x'}/>
                  </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <List hover>
                {this.state.errors.map((item, index) => (
                  <List.Item key={index} index={index}>
                    <p><b>{item}</b></p>
                  </List.Item>
                ))}
              </List>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.dismissModal.bind(this)} appearance="primary">
              Ok
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
    }
    return <div />
  }
}
