import React, { useContext, useState, useEffect } from 'react';
import { View, StyleSheet, Text } from 'react-native';
import config from '../../config/default-blueprint.json';
import { ThemeContext } from '../../../../providers/theme';
import { useViewport } from 'react-viewport-provider';
import { DashboardContainerV } from 'react-dashboard-container-rs';
import { DrawerV } from 'react-drawer-rs';
import { OrganizationProfile } from './components/OrganizationProfile';
import { ProfileTemplate } from './components/ProfileTemplate';
import { InputV } from 'react-input-rs';
import { IntroCard } from './components/IntroCard';
import { FormBuilderV, convertCredentialToSDR } from '../../../../widgets/FormBuildGenV/src';
import { FormPreview } from './components/FormPreview';

import { IProps } from './index';
import { useAuth } from '../../../../services/auth-service/auth.provider';

const humanizeString = require('humanize-string');
const BP = config.dashboard;


export const DashboardLG = (props: IProps) => {
  const theme = useContext(ThemeContext);
  const [drawer, setDrawer] = React.useState<any>({
    placement: 'right',
    title: '',
    size: 'xs',
    backdrop: false,
    show: false,
  });
  const [navBarEventKey, setNavBarEventKey] = React.useState<string>();
  const [sideBarEventKey, setSideBarEventKey] = React.useState<string>();
  const [formId, setFormId] = useState<string>('null');
  const [token, setToken] = useState<any>();
  const [drawerWidth, setDrawerWidth] = useState(330);
  const { width, height } = useViewport();
  const { getAuthTokenFast } = useAuth();

  React.useEffect(() => {
    const res: any = getAuthTokenFast();
    if (res && res.value) {
      setToken(res.value);
    }
  }, [])

  const styles = StyleSheet.create({
    sidebarHeaderContainer: {
      alignItems: 'center',
      justifyContent: 'center',
      borderWidth: StyleSheet.hairlineWidth,
      height: 0.06 * height,
    },
    sidebarHeaderTitleStyle: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: 22,
    },
  });

  const RenderSidenavHeader = () => {
    return (
      <View style={StyleSheet.flatten([styles.sidebarHeaderContainer])}>
        <Text style={StyleSheet.flatten([styles.sidebarHeaderTitleStyle])}>
          {BP.sidebarHeaderTitle}
        </Text>
      </View>
    );
  };

  const _handleEnterPress = (value: string) => {
    if (formId !== value) {
      setFormId(value);
    }
  }

  const _handleInputChange = (value: string) => {
    if (formId !== 'null') {
      setFormId('null');
    }
  }



  const renderContent = () => {

    if (sideBarEventKey === 'preview') {
      return (
        <View
          style={{
            flex: 1,
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: 10
          }}
        >
          <div style={{ width: '60%' }}>
            <InputV
              onChange={_handleInputChange}
              onEnterPress={_handleEnterPress}
              placeholder={BP?.inputFormField?.placeholder}
              title={BP?.inputFormField?.label}
            />
          </div>
          {formId && formId !== 'null' &&
            <FormPreview
              formId={formId}
            />
          }
        </View>
      );
    }
    // if (sideBarEventKey === 'formBuilder') {
    return (
      <View
        style={{
          flex: 1,
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: 10
        }}
      >
        <div style={{ width: '60%', marginBottom: 20 }}>
          <InputV
            onChange={_handleInputChange}
            onEnterPress={_handleEnterPress}
            placeholder={BP?.inputFormField?.placeholder}
            title={BP?.inputFormField?.label}
          />
        </div>
        {formId && formId !== 'null' && token &&
          <FormBuilderV
            url={props.getFormPostURL}
            label={formId}
            access_token={token}
            // formId={formId}
            drawerWidth={drawerWidth}
          />
        }
      </View>
    );
    //   }

    // return <OrganizationProfile 
    // orgProfileData={props?.orgProfileData}
    // />
    // return(
    //   <div style={{justifyContent:'center', alignItems:'center'}}>
    //     <h4 style={{textAlign:'center', alignItems:'center', position: 'absolute', top: '50%', left: '50%'}}>No Data Available</h4>
    //   </div>
    // )

  };

  const renderFooter = () => {
    return <div />;
  };

  const handleOnNavItemSelect = (eventKey: string) => {
    setNavBarEventKey(eventKey);
    if (eventKey === 'notifications') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP.notificationsDrawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
    if (eventKey === 'profile') {
      let _drawer = { ...drawer };
      _drawer.title = humanizeString(eventKey);
      _drawer.placement = BP.notificationsDrawerPlacement;
      _drawer.show = true;
      setDrawer({
        ..._drawer,
      });
    }
  };

  const handleOnSideNavItemselect = (eventKey: string) => {
    setSideBarEventKey(eventKey);
    props.sidebarNavItemSelect && props.sidebarNavItemSelect(eventKey);
  };

  const setExpanded = (eventKey: boolean) => {
    if (eventKey) {
      setDrawerWidth(330);
    } else {
      setDrawerWidth(120);
    }
  };

  const renderDrawerHeader = () => {
    return <div />;
  };

  const renderDrawerFooter = () => {
    return <div />;
  };

  const renderDrawerBody = () => {
    if (navBarEventKey === 'profile') {
      return <ProfileTemplate
        userProfile={props?.userProfile}
      />;
    }
    return <div />;
  };

  const handleOnDrawerHide = () => {
    let _drawer = { ...drawer };
    _drawer.show = false;
    setDrawer({
      ..._drawer,
    });
  };

  let _navBarItems = Array.isArray(BP?.navBarItems) && BP?.navBarItems.map(item => {
    if (item?.eventKey === 'profile') {
      return ({
        ...item,
        avatar: {
          src: props?.userProfile?.logo,
          title: props?.userProfile?.fullName.match(/\b(\w)/g)
        }
      });
    }
    else {
      return ({
        ...item
      });
    }
  })

  return (
    <>
      <DashboardContainerV
        navBarRightItems={_navBarItems}
        headerLogo={BP.headerLogo}
        headerTitle={BP.headerTitle}
        primaryColor={theme?.palette.primary}
        secondaryColor={theme?.palette.secondary}
        ternaryColor={theme?.palette.ternary}
        primaryHighlightColor={theme?.palette.primaryDark}
        sidebarWidth={180}
        sideBarItems={BP?.sidebarItems}
        renderContent={renderContent}
        headerTitleStyle={{}}
        navbarStyle={{}}
        onNavItemSelect={handleOnNavItemSelect}
        onSideNavItemSelect={handleOnSideNavItemselect}
        renderSidenavHeader={RenderSidenavHeader}
        renderFooter={renderFooter}
        isexpanded={eventKey => setExpanded(eventKey)}
      />
      <DrawerV
        placement={drawer.placement}
        show={drawer.show}
        backdrop={drawer.backdrop}
        size={drawer.size}
        renderHeader={renderDrawerHeader}
        onHide={handleOnDrawerHide}
        drawerTitle={drawer.title}
        renderBody={renderDrawerBody}
        renderFooter={renderDrawerFooter}
      />
    </>
  );
};
