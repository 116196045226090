import { ConsoleLogger, LoggerService } from 'logger-extension-vfs';
import React, { useEffect, useState } from 'react';
import { pretty } from 'react-native-helpers';
import { useMutation } from '@apollo/react-hooks';
import { LoginPage } from './components';
import Alert from 'rsuite/lib/Alert';
import { LOGIN_USER } from './mutation';
import { useHistory, useLocation, Prompt } from 'react-router-dom';
import config from '../../config/default.config.json';
import auth from '../../services/authService';
import { LogoSpinnerV } from 'react-logo-spinner-ext';
import { useProvLogin } from './provider';
import { HttpServer } from '../../servers/HttpServer';

import { useAuth } from '../../services/auth-service/auth.provider';

const console: LoggerService = new LoggerService(
  new ConsoleLogger('Login:container'),
);

const http = new HttpServer();

export const Login = () => {

  // const [LoginUser, { data, loading, error, called }] = useMutation(LOGIN_USER);
  const { signIn, getAuthToken } = useAuth();
  const [remember, setRememberMe] = useState<boolean>(false);
  const [loginInProgess, setLoggingInProgress] = useState<boolean>(false);
  const [loginError, setLoginError] = useState<any>();
  const [loginResult, setLoginResult] = useState<any>();
  const [isPreRendering, setIsPreRendering] = useState(true);
  const history = useHistory();

  useEffect(() => {
    if (
      loginError
    ) {
      console.info(`login error ${pretty(loginError)}`);
      // Alert.warning(`${loginUserError.graphQLErrors[0].message.message}`, 10000);
      history.push('/dashboard');
    }
  }, [loginError]);

  useEffect(() => {
    auth.getToken().then((token: any) => {
      if (token) {
        history.push('/Dashboard');
      }
      setIsPreRendering(false);
    })
  }, [])

  if (loginInProgess || isPreRendering) {
    return <LogoSpinnerV />;
  }


  if (loginResult) {
    console.info(`on login : ${pretty(loginResult)}`);
    Alert.success('Successfully Logged In', 2000);
    if (loginResult.user?.access_token) {
      console.info(`remember me => ${remember}`);
      signIn({
        newToken: loginResult.user?.access_token,
        rememberMe: remember,
        actorName: 'admin',
        userProfile: {
          fullName: loginResult.user?.name,
          email: loginResult.user?.email,
        },
      });
      console.info(`entered :::::::::::::::::`);
      history.push('/dashboard');
    }
  }

  const onLoginClick = async (
    email: string,
    password: string,
    rememberMe: boolean,
  ) => {
    console.debug(
      `email: ${email}, password: ${password}, rememberMe: ${rememberMe}`,
    );
    if (rememberMe) {
      setRememberMe(true)
    } else if (remember === true) {
      setRememberMe(false)
    }
    if (email && password) {
      setLoggingInProgress(true);
      try {
        const res = await http.post(config.servers.http.loginEP, {
          email: email,
          password: password
        });
        if (res) {
          console.debug(`Login Result => ${pretty(res.data)}`);
          setLoginResult(res.data);
        }
        else {
          setLoginError(`Login Failed`);
        }
      }
      catch (err) {
        console.debug(`Error in login ${pretty(err)}`)
        setLoginError(`Login Failed`);
      }
      setLoggingInProgress(false);
    }
  };
  const _renderBody = () => {
    return <LoginPage onLogin={onLoginClick} />;
  };
  return <> {_renderBody()}</>;
};
